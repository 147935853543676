

























import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { v4 as uuidv4 } from "uuid";

@Component
export default class CosmopolSelect extends Vue {
  @PropSync("selected", {
    required: false,
    default: null,
    type: [String, Number],
  })
  private _selected: string | number;
  @Prop({ required: true, default: () => [], type: Array })
  private items: [];
  @Prop({ required: false, default: null, type: [String, Number] })
  private itemText: string | number;
  @Prop({ required: false, default: null, type: [String, Number] })
  private itemValue: string | number;
  @Prop({ required: false, default: true, type: Boolean })
  private hideDetails: boolean;
  @Prop({ required: false, default: false, type: Boolean })
  private hideSelected: boolean;
  @Prop({ required: false, default: true, type: Boolean })
  private clearable: boolean;
  @Prop({ required: false, default: null, type: String })
  private label: string;
  @Prop({ required: false, default: null, type: String })
  private placeholder: string;

  private id = uuidv4();

  private onSelectChange(item: string): void {
    this._selected = item;
    this.$emit("on-change", item);
  }
}
